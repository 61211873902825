// react imports
import { FC, useContext, useEffect, useState, ReactElement, useMemo, useCallback } from 'react';
import { IconButton, Stack } from '@fluentui/react';
import { useNavigate, Link } from 'react-router-dom';
// state imports
import { UserAppContext } from '../state/userContext';
import { AppContext } from '../state/applicationState';
import { bindActionCreators } from '../state/actions/actionCreators';
import { UserActions } from '../state/actions/userActions';
import * as userActions from '../state/actions/userActions';
import { ConstellationActions } from '../state/actions/constellationActions';
import * as constellationActions from '../state/actions/constellationActions';
import { ClusterActions } from '../state/actions/clusterActions';
import * as clusterActions from '../state/actions/clusterActions';
import { NoteActions } from '../state/actions/noteActions';
import * as noteActions from '../state/actions/noteActions';
import { DisplayActions } from '../state/actions/displayActions';
import * as displayActions from '../state/actions/displayActions';
// ux imports
import { headerLogoStyles, headerLoginStyle, headerLoginButtonStyle, headerLogoButtonStyle, headerUpdatesStyle, headerUpdatesButtonStyle, headerStackStyle } from '../ux/panes/header';

const Header: FC = (): ReactElement => {
    const appContext = useContext<AppContext>(UserAppContext)
    const actions = useMemo(() => ({
        user: bindActionCreators(userActions, appContext.dispatch) as unknown as UserActions,
        constellation: bindActionCreators(constellationActions, appContext.dispatch) as unknown as ConstellationActions,
        cluster: bindActionCreators(clusterActions, appContext.dispatch) as unknown as ClusterActions,
        note: bindActionCreators(noteActions, appContext.dispatch) as unknown as NoteActions,
        display: bindActionCreators(displayActions, appContext.dispatch) as unknown as DisplayActions
    }), [appContext.dispatch]);
    const navigate = useNavigate();
    const [logInOrOut, setLogInOrOut] = useState<() => void>(() => () => handleLogin(navigate));
    const [signInOrOut, setSignInOrOut] = useState(() => "Signin");

    // functions
    const handleReturnToHome = () => {
        actions.cluster.setClusterBy('');
        actions.note.setSelectedContent(null);
        actions.constellation.setConstellationName("Home");
    }
    const handleUpdates = () => {
        navigate(`/updates`)
    }
    const handleLogin = useCallback((navigate) => {
        console.log("handleLogin called");
        navigate('/login');
    }, []); 
    const handleLogout = useCallback((navigate) => {
        console.log("handleLogout called");
        actions.user.setUser(false, "");
        navigate(`/auth/logout?post_logout_redirect_uri=${window.location.origin}/`);
    }, [actions.user]);

    // effects
    useEffect(() => {
        console.log("login or out")
        if(appContext.state.userState?.isLoggedIn){
            setLogInOrOut(() => () => handleLogout(navigate));
            setSignInOrOut(() => "SignOut");
        } else {
            setLogInOrOut(() => () => handleLogin(navigate));
            setSignInOrOut(() => "Signin");
        }
    }, [appContext.state.userState?.isLoggedIn, actions.user, handleLogin, handleLogout, navigate]);

    return (
        <Stack horizontal styles={headerStackStyle}>
            <Stack.Item styles={headerLogoStyles}>
                <Link to="/constellation" onClick={handleReturnToHome}>
                    <img src={`${process.env.PUBLIC_URL}/cnstlltn_logo.png`} alt="Logo" style={headerLogoButtonStyle} />
                </Link>
            </Stack.Item>
            <Stack horizontal>
                <Stack.Item styles={headerUpdatesStyle}>
                    <IconButton aria-label="Updates" iconProps={{ iconName: "Info" }} onClick={handleUpdates} styles={headerUpdatesButtonStyle} />
                </Stack.Item>
                <Stack.Item styles={headerLoginStyle}>
                    <IconButton aria-label="Login" iconProps={{ iconName: signInOrOut }} onClick={logInOrOut} styles={headerLoginButtonStyle} />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export default Header;