import { CnstlltnTheme } from "../shared/theme"

const theme = CnstlltnTheme;

export const whiteLoadingDots = {
    color: theme.palette.white,
    padding: 10,
}

export const blackLoadingDots = {
    color: theme.palette.black,
    padding: 10,
}

