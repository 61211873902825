import { IStackStyles } from '@fluentui/react'

export const constellationQueryPageStyle: IStackStyles = {
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    }
}
export const aiPageStyle: IStackStyles = {
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'auto',
    }
}

export const welcomePageStyle: IStackStyles = {
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    }
}

export const constellationPageStyle: IStackStyles = {
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    }
}

export const notePageStyle: IStackStyles = {
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
    }
}