import { createTheme } from '@fluentui/react';

export const CnstlltnTheme = createTheme({
    palette: {
        themePrimary: '#0c343c', // dark blue
        themeLighterAlt: '#00060a',
        themeLighter: '#001729',
        themeLight: '#e3e3e3', // light grey
        themeTertiary: '#025799',
        themeSecondary: '#3a3a3a', // dark grey
        themeDarkAlt: '#1c9dff',
        themeDark: '#3facff',
        themeDarker: '#0c343c',
        neutralLighterAlt: '#323232',
        neutralLighter: '#3a3a3a',
        neutralLight: '#484848',
        neutralQuaternaryAlt: '#505050',
        neutralQuaternary: '#575757',
        neutralTertiaryAlt: '#747474',
        neutralTertiary: '#ececec',
        neutralSecondary: '#efefef',
        neutralPrimaryAlt: '#f2f2f2',
        neutralPrimary: '#e3e3e3',
        neutralDark: '#f9f9f9',
        black: '#292929', // black
        white: '#fcfcfc', // white
    }
});